// .cache/gatsby-mdx/wrapper-components/{wrapper-filepath-hash}-{scope-hash}.js
import React from 'react';
import { MDXScopeProvider } from 'gatsby-mdx/context';

import __mdxScope_0 from '/Users/ryan/projects/clients/uofm-publishing/production/exploregabii/.cache/gatsby-mdx/mdx-scopes-dir/3010b3badc54a9dfa4a4c80e419a41b2.js';

import OriginalWrapper from '/Users/ryan/projects/clients/uofm-publishing/production/exploregabii/src/templates/current-excavations.js';

import { graphql } from 'gatsby';

// pageQuery, etc get hoisted to here
;

export default ({children, ...props}) => <MDXScopeProvider __mdxScope={{...__mdxScope_0}}>
  <OriginalWrapper
    {...props}
  >
    {children}
  </OriginalWrapper>
</MDXScopeProvider>